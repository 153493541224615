module.exports = [{
      plugin: require('../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-5ba1abafff/2/.yarn/berry/cache/gatsby-plugin-manifest-npm-5.12.3-f2709a1ddc-10c0.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"2024 당근 전직군 공개 채용","short_name":"2024 당근 전직군 공개 채용","start_url":"/","background_color":"#ffffff","display":"minimal-ui","icon":"src/assets/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"faa29da546934f91eb94665bbe915e8f"},
    },{
      plugin: require('../../.yarn/__virtual__/gatsby-plugin-google-tagmanager-virtual-0ca9453262/2/.yarn/berry/cache/gatsby-plugin-google-tagmanager-npm-5.13.0-6ef9bd033b-10c0.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TF63G9M9","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../../.yarn/__virtual__/gatsby-plugin-seed-design-virtual-4a881b1796/2/.yarn/berry/cache/gatsby-plugin-seed-design-npm-0.2.7-7f54956ea7-10c0.zip/node_modules/gatsby-plugin-seed-design/gatsby-browser.tsx'),
      options: {"plugins":[],"mode":"light-only"},
    },{
      plugin: require('../../../.yarn/berry/cache/gatsby-plugin-anchor-links-npm-1.2.1-7db63a8548-10c0.zip/node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100,"duration":700},
    },{
      plugin: require('../../.yarn/__virtual__/gatsby-plugin-google-tagmanager-virtual-0ca9453262/2/.yarn/berry/cache/gatsby-plugin-google-tagmanager-npm-5.13.0-6ef9bd033b-10c0.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TF63G9M9","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../../../.yarn/berry/cache/gatsby-plugin-smoothscroll-npm-1.2.0-d53876c6b9-10c0.zip/node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../.yarn/unplugged/gatsby-virtual-49ac8e9ed8/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
